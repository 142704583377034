import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'iwt-address-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'Sample Page';
  errorMessage = '';
  createAddressModalOpen = false;
  language = 'de';
  viewport: string;

  tokenControl = new FormControl();

  private destroy$ = new ReplaySubject();

  constructor(
    public translate: TranslateService,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
    const addressHref = document.location.href.startsWith('http://localhost')
      ? 'http://localhost:3003/'
      : document.location.href;

    const webcomponents = [
      `${addressHref}address-components.js`,
      'https://move.dev.estatepro.de/shared-components.js',
    ];
    this.loadWebComponents(webcomponents);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  ngOnInit(): void {
    this.setActualViewHeight();

    this.breakpointObserver
      .observe('(max-width: 767px)')
      .pipe(takeUntil(this.destroy$))
      .subscribe((breakpointState) => {
        this.viewport = breakpointState.matches ? 'small' : 'large';
      });
  }

  track(event: any) {
    console.log('Tracking called with:', event?.detail);
  }

  @HostListener('window:resize')
  setActualViewHeight(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  loadWebComponents(urls: string[]) {
    const existingElement = document.getElementsByTagName('script')[0];
    urls.forEach((url) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = url;
      existingElement?.parentNode?.insertBefore(script, existingElement);
    });
  }

  createAddressModalClose(event: any): void {
    event = event.detail;
    this.createAddressModalOpen = false;
    if (event?.redirect) {
      this.router.navigateByUrl('/addresses/' + event.entityUuid);
    }
  }

  openCreationModal(): void {
    this.createAddressModalOpen = true;
  }

  createErrorMessage(msg: any) {
    if (!msg?.detail) {
      return;
    }

    this.errorMessage = msg.detail.message;
  }

  showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  closeErrorMessage(): void {
    this.errorMessage = '';
  }

  setLanguage(language: string) {
    this.language = language;
    this.translate.use(language);
  }
}
